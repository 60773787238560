import Vue from 'vue';
import VueRouter from 'vue-router';
import { getProgress } from '../services/commonService';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/join',
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/join',
    name: 'Join',
    component: () => import(/* webpackChunkName: "join" */ '../views/Join.vue'),
  },
  {
    path: '/join/:code200',
    name: 'JoinReferral',
    component: () => import(/* webpackChunkName: "join" */ '../views/Join.vue'),
  },
  {
    path: '/servicetype',
    name: 'ServiceType',
    component: () => import(/* webpackChunkName: "ServiceType" */ '../views/ServiceType.vue'),
  },
  {
    path: '/primaryregistration',
    name: 'PrimaryRegistration',
    component: () => import(/* webpackChunkName: "primaryregistration" */ '../views/PrimaryRegistration.vue'),
  },
  {
    path: '/socialloginverification/:code5/:code6',
    name: 'SocialLoginVerification',
    component: () => import(/* webpackChunkName: "socialloginverification" */ '../views/SocialLoginVerification.vue'),
    /*  beforeEnter: (to, from, next) => {
        function isValid(param) {
          // check if param is valid
          return true;
        }
        if (!isValid(to.params.code3)) {
          next({ name: "Notfound" });
        }
        next();
      }, */
  },
  {
    path: '/magicallinkverification',
    name: 'MagicalLinkVerification',
    component: () => import(/* webpackChunkName: "magicallinkverification" */ '../views/MagicalLink.vue'),
  },
  {
    path: '/otpverification/:code',
    name: 'OtpVerification',
    component: () => import(/* webpackChunkName: "otpverification" */ '../views/OtpVerification.vue'),
  },
  {
    path: '/otpverificationsocial',
    name: 'OtpVerificationSocial',
    component: () => import(/* webpackChunkName: "otpverificationsocial" */ '../views/OtpVerificationSocial.vue'),
  },
  {
    path: '/completeyourprofile/:code',
    name: 'CompleteYourProfile',
    component: () => import(/* webpackChunkName: "completeyourprofile" */ '../views/CompleteYourProfile.vue'),
  },
  {
    path: '/sheltercompleteprofile',
    name: 'ShelterCompleteProfile',
    component: () => import(/* webpackChunkName: "sheltercompleteprofile" */ '../views/ShelterCompleteProfile.vue'),
  },
  {
    path: '/accountconfirmation',
    name: 'AccountConfirmation',
    component: () => import(/* webpackChunkName: "accountconfirmation" */ '../views/AccountConfirmation.vue'),
  },
  {
    path: '/petoverview/:code2',
    name: 'PetOverview',
    component: () => import(/* webpackChunkName: "petoverview" */ '../views/PetOverview.vue'),
  },
  {
    path: '/petoverview/:code7/:code8',
    name: 'PetOverview1',
    component: () => import(/* webpackChunkName: "petoverview" */ '../views/PetOverview.vue'),
  },
  // {
  //   path: "/petdeceased",
  //   name: "PetDeceased",
  //   component: () =>
  //     import(/* webpackChunkName: "petdeceased" */ "../views/PetDeceased.vue"),
  // },
  {
    path: '/sharingsetting',
    name: 'SharingSetting',
    component: () => import(/* webpackChunkName: "sharingsetting" */ '../views/SharingSetting.vue'),
  },
  {
    path: '/sharingsettingnew',
    name: 'SharingSettingNew',
    component: () => import(/* webpackChunkName: "sharingsetting" */ '../views/SharingSettingNew.vue'),
  },
  {
    path: '/notificationfeed',
    name: 'NotificationFeed',
    component: () => import(/* webpackChunkName: "notificationfeed" */ '../views/NotificationFeed.vue'),
  },
  {
    path: '/notificationsettings',
    name: 'NotificationSettings',
    component: () => import(/* webpackChunkName: "notificationsettings" */ '../views/NotificationSettings.vue'),
  },
  {
    path: '/newpetrecord',
    name: 'NewPetrecord',
    component: () => import(/* webpackChunkName: "newpetrecord" */ '../views/NewPetrecord.vue'),
  },
  {
    path: '/newpetferret',
    name: 'NewPetFerret',
    component: () => import(/* webpackChunkName: "newpetferret" */ '../views/NewPetFerret.vue'),
  },
  {
    path: '/newpetbird',
    name: 'NewPetBird',
    component: () => import(/* webpackChunkName: "newpetbird" */ '../views/NewPetBird.vue'),
  },
  {
    path: '/newpetcat',
    name: 'NewPetCat',
    component: () => import(/* webpackChunkName: "newpetcat" */ '../views/NewPetCat.vue'),
  },
  {
    path: '/newpetdog',
    name: 'NewPetDog',
    component: () => import(/* webpackChunkName: "newpetdog" */ '../views/NewPetDog.vue'),
  },
  {
    path: '/petrecord',
    name: 'PetRecord',
    component: () => import(/* webpackChunkName: "dogrecord" */ '../views/PetRecord.vue'),
  },
  {
    path: '/dogrecord',
    name: 'DogRecord',
    component: () => import(/* webpackChunkName: "dogrecord" */ '../views/DogRecord.vue'),
  },
  {
    path: '/catrecord',
    name: 'CatRecord',
    component: () => import(/* webpackChunkName: "catrecord" */ '../views/CatRecord.vue'),
  },
  {
    path: '/birdrecord',
    name: 'BirdRecord',
    component: () => import(/* webpackChunkName: "birdrecord" */ '../views/BirdRecord.vue'),
  },
  {
    path: '/ferretrecord',
    name: 'FerretRecord',
    component: () => import(/* webpackChunkName: "ferretrecord" */ '../views/FerretRecord.vue'),
  },
  {
    path: '/newpet',
    name: 'NewPet',
    component: () => import(/* webpackChunkName: "ferretrecord" */ '../views/NewPet'),
  },
  {
    path: '/otherrecord',
    name: 'OtherRecord',
    component: () => import(/* webpackChunkName: "otherrecord" */ '../views/OtherRecord.vue'),
  },
  {
    path: '/transferownership',
    name: 'TransferOwnership',
    component: () => import(/* webpackChunkName: "transferownership" */ '../views/TransferOwnership.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
  },

  {
    path: '/shelteraccount',
    name: 'ShelterAccount',
    component: () => import(/* webpackChunkName: "shelteraccount" */ '../views/ShelterAccount.vue'),
  },
  {
    path: '/veterinarianaccount',
    name: 'VeterinarianAccount',
    component: () => import(/* webpackChunkName: "veterinarianaccount" */ '../views/VeterinarianAccount.vue'),
  },
  {
    path: '/businessprofileaccount/:account',
    name: 'BusinessProfileAccount',
    component: () => import(/* webpackChunkName: "businessprofileaccount" */ '../views/BusinessProfileAccount.vue'),
  },
  {
    path: '/guestinvite',
    name: 'GuestInvite',
    component: () => import(/* webpackChunkName: "guestinvite" */ '../views/GuestInvite.vue'),
  },
  {
    path: '/invitation/:codeinvitation',
    name: 'Invitation',
    component: () => import(/* webpackChunkName: "invitation" */ '../views/Invitation.vue'),
  },
  {
    path: '/dogbreedcompleteprofile/:code',
    name: 'DogBreedCompleteProfile',
    component: () => import(/* webpackChunkName: "DogBreedCompleteProfile" */ '../views/DogBreedCompleteProfile.vue'),
  },
  {
    path: '/veterinariancompleteprofile',
    name: 'VeterinarianCompleteProfile',
    component: () =>
      import(/* webpackChunkName: "VeterinarianCompleteProfile" */ '../views/VeterinarianCompleteProfile.vue'),
  },
  {
    path: '/guestpetoverview',
    name: 'GuestPetOverview',
    component: () => import(/* webpackChunkName: "Guestpetoverview" */ '../views/GuestPetOverview.vue'),
  },
  {
    path: '/dogbreedaccount',
    name: 'DogBreedAccount',
    component: () => import(/* webpackChunkName: "dogbreedaccount" */ '../views/DogBreedAccount.vue'),
  },
  {
    path: '/breederprofile/:id',
    name: 'BreederProfile',
    component: () => import(/* webpackChunkName: "breederprofile" */ '../views/BreederProfile.vue'),
  },
  {
    path: '/manageteam',
    name: 'ManageTeam',
    component: () => import(/* webpackChunkName: "manageteam" */ '../views/ManageTeam.vue'),
  },
  {
    path: '/petownerdiary/dashboard',
    name: 'PetownerDiaryDashboard',
    component: () => import(/* webpackChunkName: "petownerdiary" */ '../views/PetownerDiaryDashboard.vue'),
  },
  {
    path: '/petownerdiary/view',
    name: 'PetownerDiaryView',
    component: () => import(/* webpackChunkName: "petownerdiary" */ '../views/PetownerDiaryView.vue'),
  },
  {
    path: '/petownerdiary/allentries',
    name: 'PetownerDiaryAllentries',
    component: () => import(/* webpackChunkName: "petownerdiary" */ '../views/PetownerDiaryAllentries.vue'),
  },
  {
    path: '/insurancetoolcomingsoon',
    name: 'InsuranceToolComingSoon',
    component: () => import(/* webpackChunkName: "insurancetoolcomingsoon" */ '../views/InsuranceToolComingSoon.vue'),
  },
  {
    path: '/petownemanagesubscription',
    name: 'PetOwneManageSubscription',
    component: () =>
      import(/* webpackChunkName: "petOwnemanagesubscription" */ '../views/PetOwneManageSubscription.vue'),
  },
  {
    path: '/serviceprovidermanagesubscription',
    name: 'ServiceProviderManageSubscription',
    component: () =>
      import(
        /* webpackChunkName: "ServiceProviderManageSubscription" */ '../views/ServiceProviderManageSubscription.vue'
      ),
  },
  {
    path: '/serviceprovideraccount',
    name: 'ServiceProviderAccount',
    component: () => import(/* webpackChunkName: "serviceprovideraccount" */ '../views/ServiceProviderAccount.vue'),
  },
  {
    path: '/costtoolcomingsoon',
    name: 'CostToolComingSoon',
    component: () => import(/* webpackChunkName: "costtoolcomingsoon" */ '../views/CostToolComingSoon.vue'),
  },
  {
    path: '/serviceprovidercompleteprofile',
    name: 'ServiceproviderCompleteProfile',
    component: () =>
      import(/* webpackChunkName: "serviceprovidercompleteprofile" */ '../views/ServiceProviderCompleteProfile.vue'),
  },
  {
    path: '/referralprogram',
    name: 'ReferralProgram',
    component: () => import(/* webpackChunkName: "ReferralProgram" */ '../views/ReferralProgram.vue'),
  },
  {
    path: '/routineoverview',
    name: 'RoutineOverview',
    component: () => import(/* webpackChunkName: "RoutineOverview" */ '../views/RoutineOverview.vue'),
  },
  {
    path: '/routineoverviewpet', //"/routineoverviewpet/:id",
    name: 'RoutineOverviewPet',
    component: () => import(/* webpackChunkName: "RoutineOverviewPet" */ '../views/RoutineOverviewPet.vue'),
  },
  {
    path: '/routineoverview/public/:id',
    name: 'RoutineOverviewPublic',
    component: () => import(/* webpackChunkName: "RoutineOverviewPublic" */ '../views/RoutineOverviewPublic.vue'),
  },
  // {
  //   path: "/trainingplan",
  //   name: "TrainingPlan",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "trainingplan" */ "../views/TrainingPlan.vue"
  //     ),
  // },
  {
    path: '/vaccination',
    name: 'Vaccination',
    component: () => import(/* webpackChunkName: "vaccination" */ '../views/Vaccination.vue'),
  },
  {
    path: '/communityoverview',
    name: 'CommunityOverview',
    component: () => import(/* webpackChunkName: "communityoverview" */ '../views/CommunityOverview.vue'),
  },
  {
    path: '/intakequestionnaire',
    name: 'IntakeQuestionnaire',
    component: () => import(/* webpackChunkName: "intakequestionnaire" */ '../views/IntakeQuestionnaire.vue'),
  },
  {
    path: '/medicalhistorydashboard',
    name: 'MedicalHistoryDashboard',
    component: () => import(/* webpackChunkName: "medicalhistorydashboard" */ '../views/MedicalHistoryDashboard.vue'),
  },
  {
    path: '/medicalsharedfiles/petowner',
    name: 'MedicalPetOwnerFile',
    component: () => import(/* webpackChunkName: "medicalsharedfiles" */ '../views/MedicalPetOwnerFile.vue'),
  },
  {
    path: '/medicalsharedfiles/breeder',
    name: 'MedicalBreederFile',
    component: () => import(/* webpackChunkName: "medicalsharedfiles" */ '../views/MedicalBreederFile.vue'),
  },
  {
    path: '/nutritionplan',
    name: 'NutritionPlan',
    component: () => import(/* webpackChunkName: "nutritionplan" */ '../views/NutritionPlan.vue'),
  },
  {
    path: '/diagnosesoverviewmedicalhistory',
    name: 'DiagnosesOverviewMedicalHistory',
    component: () =>
      import(/* webpackChunkName: "diagnosesoverviewmedicalhistory" */ '../views/DiagnosesOverviewMedicalHistory.vue'),
  },
  {
    path: '/addvaccination',
    name: 'AddVaccination',
    component: () => import(/* webpackChunkName: "addvaccination" */ '../views/AddVaccination.vue'),
  },
  {
    path: '/recentactivity',
    name: 'RecentActivity',
    component: () => import(/* webpackChunkName: "recentactivity" */ '../views/RecentActivity.vue'),
  },
  {
    path: '/browseservicedirectory',
    name: 'BrowseServiceDirectory',
    component: () => import(/* webpackChunkName: "browseservicedirectory" */ '../views/BrowseServiceDirectory.vue'),
  },

  {
    path: '/browsebreederdirectory',
    name: 'BrowseBreederDirectory',
    component: () => import(/* webpackChunkName: "browsebreederdirectory" */ '../views/BrowseBreederDirectory.vue'),
  },
  {
    path: '/breedersearchresults',
    name: 'BreederSearchResults',
    component: () => import(/* webpackChunkName: "breedersearchresults" */ '../views/BreederSearchResults.vue'),
  },
  {
    path: '/businessprofileview/:id',
    name: 'BusinessProfileView',
    component: () => import(/* webpackChunkName: "businessprofileview" */ '../views/BusinessProfileView.vue'),
  },
  {
    path: '/servicesearchresults',
    name: 'ServiceSearchResults',
    component: () => import(/* webpackChunkName: "servicesearchresults" */ '../views/ServiceSearchResults.vue'),
  },
  {
    path: '/careblog',
    name: 'CareBlog',
    component: () => import(/* webpackChunkName: "careblog" */ '../views/CareBlog.vue'),
  },
  {
    path: '/lostpetlisting',
    name: 'LostPetListing',
    component: () => import(/* webpackChunkName: "lostpetlisting" */ '../views/LostPetListing.vue'),
  },
  {
    path: '/lostmodeprofile',
    name: 'LostModeProfile',
    component: () => import(/* webpackChunkName: "lostmodeprofile" */ '../views/LostModeProfile.vue'),
  },
  {
    path: '/onboardingdashboard',
    name: 'OnboardingDashboard',
    component: () => import(/* webpackChunkName: "onboardingdashboard" */ '../views/OnboardingDashboard.vue'),
    // meta: {
    //   requiresAuth: true, // Add meta information for route
    // },
  },
  {
    path: '/sppassivedashboard/:code11',
    name: 'SpPassiveDashboard',
    component: () => import(/* webpackChunkName: "sppassivedashboard" */ '../views/SpPassiveDashboard.vue'),
  },
  {
    path: '/directusdisplayprofile/:codepet',
    name: 'DirectusDisplayProfile',
    component: () => import(/* webpackChunkName: "sppassivedashboard" */ '../views/DirectusDisplayProfile.vue'),
  },
  {
    path: '/directusdisplaydoc/:codepet',
    name: 'DirectusDisplayDoc',
    component: () => import(/* webpackChunkName: "sppassivedashboard" */ '../views/DirectusDisplayDoc.vue'),
  },
  {
    path: '/publicprofile/:view',
    name: 'PublicProfile',
    component: () => import(/* webpackChunkName: "publicprofile" */ '../views/PublicProfile.vue'),
  },
  {
    path: '/publicprofile/publicPetSharingSP/:codePS',
    name: 'publicPetSharingSP',
    component: () => import(/* webpackChunkName: "publicprofile" */ '../views/PublicProfile.vue'),
  },
  {
    path: '/publicprofile/publicByUrlQR/:code10',
    name: 'PublicProfileNonContact',
    component: () => import(/* webpackChunkName: "petoverview" */ '../views/PublicProfile.vue'),
  },
  {
    path: '/datainsightsoverview/:id',
    name: 'DataInsightsOverview',
    component: () => import(/* webpackChunkName: "datainsightsoverview" */ '../views/DataInsightsOverview.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue'),
  },
  {
    path: '/healthlogoverview',
    name: 'HealthLogOverview',
    component: () => import(/* webpackChunkName: "healthlogoverview" */ '../views/HealthLogOverview.vue'),
  },
  {
    path: '/healthlogoverview/visualizedhealth',
    name: 'VisualizedHealth',
    component: () => import(/* webpackChunkName: "visualizedhealth" */ '../views/VisualizedHealth.vue'),
  },
  {
    path: '/healthlogoverview/medication',
    name: 'MedicationHealthLog',
    component: () => import(/* webpackChunkName: "medication" */ '../views/MedicationHealthLog.vue'),
  },
  {
    path: '/healthlogoverview/parasitetreatment',
    name: 'ParasiteTreatment',
    component: () => import(/* webpackChunkName: "parasitetreatment" */ '../views/ParasiteTreatmentHealthLog.vue'),
  },
  {
    path: '/healthlogoverview/addmedication',
    name: 'AddMedication',
    component: () => import(/* webpackChunkName: "addmedication" */ '../views/AddMedicationHealthLog.vue'),
  },
  {
    path: '/healthlogoverview/selectsymptom',
    name: 'SelectSymptom',
    component: () => import(/* webpackChunkName: "selectsymptom" */ '../views/SelectSymptomHealthLog.vue'),
  },
  {
    path: '/locationonmap/:lon/:lat',
    name: 'LocationOnMap',
    component: () => import(/* webpackChunkName: "locationonmap" */ '../views/LocationOnMap.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   // Fetch user data using the action before navigating
//   const { data: progress } = await getProgress();
//   console.log({ progress });

//   const percentageProgress = progress.progress;

//   // Example: Check if the user is authenticated before navigating
//   if (percentageProgress != 100) {
//     next('/onboardingdashboard');
//   } else {
//     // Continue with the navigation
//     next();
//   }
// });

export default router;
